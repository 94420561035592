<template>
  <div class="mb-15">
    <Overlay :overlay="overlay"/>
    <v-row class="mx-sm-8 mx-2">
      <v-col cols="6">
        <h1 class="mt-10  ">Suficiencia</h1>
      </v-col>
      <v-col cols="6" class="mt-10" v-if="this.formatosId">
        <CheckButtons 
          :tenderId="this.tenderId"
          :formatosId="this.formatosId"
        />
      </v-col>
      <v-col cols="12">
        <hr class="">
      </v-col>

      <v-col v-if="!overlay" cols="12">
        <h3>   Editar Suficiencia</h3>
        <v-form ref="form" class="mt-10" @submit.prevent="submitForm">
          <v-row class="mb-4">
            <v-expansion-panels inset>
              <v-expansion-panel
              >
                <v-expansion-panel-header>Adjunta Documento</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12">
                    <FileInputEdit
                        :tenderId="this.tenderId"
                        :formatosId="this.formatosId"
                        :file="data.suficiencia_url"
                        :file-tmp="fileTmp"
                        @getImageFile="handleImageFile"
                        @changeShowImage="handleShowImage"
                    />
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-col v-if="fileTmp" cols="6" class="mt-6" >
              <v-btn  @click="downloadImage" type="button" color="primary" style="width: 200px">
                Descargar archivo
              </v-btn>
            </v-col>
            <v-col  v-if="!fileTmp" cols="6" class="mt-6" >
              <v-btn type="submit" color="primary" style="width: 200px">
                Guardar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>

    </v-row>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import CheckButtons from "../../components/CheckButtons";
import FileInputEdit from "../../components/FileInputEdit";
import Overlay from "../../components/Overlay";
import Trumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.min.css';
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.js'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/pasteembed/trumbowyg.pasteembed.js'
import 'trumbowyg/dist/plugins/upload/trumbowyg.upload'
import 'trumbowyg/dist/plugins/base64/trumbowyg.base64'
import {URL_API_IMAGES} from "@/store/config";

export default {
  name: "DetailSufficiency",
  data() {
    return {
      overlay: false,
      tenderId: null,
      externalId: null,
      documentComplete:null,
      documentGenerate:null,
      documentGenerateStatus:false,
      id: null,
      formatosId:null,
      fileTmp:null,
      fileSend:null,
      data: {
        suficiencia_url: "",
        documentation: "",
        specificationsService: "",
        trainingRequired: '',
        authorizations: '',
        requirements: '',
        responsibility: '',
        conditionsPurchase: '',
      },
    };
  },
  components: {
    CheckButtons,
    FileInputEdit,
    Overlay,
    Trumbowyg
  },
  async created() {
    this.tenderId = parseInt(this.$route.params.id);
    this.id = parseInt(this.$route.params.marketId);
    await this.findMarketOne();
  },
  methods: {
    ...mapActions('sufficiency', ['updateSufficiency', 'findOneSufficiency','generateHtmlFormat','downloadFile']),
    ...mapActions('tenders', ['findOneTenders']),
    async downloadImage() {
      try {
        const url = `${URL_API_IMAGES}${this.data.suficiencia_url}`;
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Error al descargar el archivo');
        }

        const blob = await response.blob();
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'archivo';

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
          const matches = /filename="([^"]*)"/.exec(contentDisposition);
          if (matches != null && matches[1]) {
            fileName = matches[1];
          }
        } else {
          const urlParts = url.split('/');
          fileName = urlParts[urlParts.length - 1];
        }

        // Crea un enlace temporal para descargar el archivo
        const urlBlob = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = urlBlob;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo', error);
      }
    },
    handleImageFile(file) {
      this.data.suficiencia_url = file;
      this.fileSend = file;
      this.fileTmp = '';
    },

    handleShowImage(show) {
    },

    async findMarketOne() {
      this.overlay = true;
      const response = await this.findOneSufficiency({id: this.id, tenderId: this.tenderId});
      if (response) {
        this.documentComplete = response.documentoCompleto
        this.data.formatData = response.formatoData;
        this.data.suficiencia_url = response.suficiencia_url;
        this.fileTmp = response.suficiencia_url;
        this.formatosId = response.formatosId;
        const responseExternal = await this.findOneTenders(response.licitacionesId);
        if (responseExternal){
          this.externalId = responseExternal.claveLicitacion;
        }else{
          this.externalId =null;
        }
        this.overlay = false;
      } else {
        await this.$router.push(`/licitaciones_documentos/${this.tenderId}`)
      }
    },
    async validateRequired(){
      if (!this.fileSend){
        this.sweetAlertWarning('Campo Documento es necesario');
        return false
      }
      
      return true

    },
    async submitForm() {
      const validateData = await this.validateRequired();
      if (!validateData){
        return
      }
      this.overlay = true;
      const response = await this.updateSufficiency({id: this.id, data: this.fileSend});
      if (!response.error) {
        this.fileSend = '';
        this.data.suficiencia_url = '';
        this.fileTmp = '';
        await this.findMarketOne();
        this.sweetAlertSuccess('Se edito correctamente')
      } else {
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async generateData() {
      this.overlay = true;
      const response = await this.generateHtmlFormat({id: this.id});
      if (!response.error) {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
      } else {
        this.documentGenerate = response;
        this.documentGenerateStatus = true;
        this.sweetAlertError(response.error)
      }
      this.overlay = false;
    },

    async downloadFileServer() {
      this.overlay = true;
      await this.downloadFile({id: this.id,externalId: this.externalId});
      this.overlay = false;
    },
  },
}
</script>

<style scoped>
.v-list-item .v-list-item__title, .v-list-item__icon .v-icon {
  font-size: 14px;
  color: white;
}

.bg-radio-button {
  background-color: #D9D9D9;
  padding: 30px;
}

</style>
